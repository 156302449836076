














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { toLocalShortDate } from '@/utils/dateFormatter'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'BaseDatepicker',
  components: {
    VueDatePicker,
    BaseIcon,
  },
})
export default class extends Vue {
  @Prop({ default: () => uuidv4() })
  id!: string

  @Prop()
  value!: Date | string | number

  @Prop({ default: '' })
  placeholder!: string

  @Prop()
  label!: string

  @Prop()
  error!: string

  @Prop()
  classWrap!: string

  @Prop({ default: false })
  disabled!: boolean

  get displayValue(): string {
    return this.value ? this.toLocalShortDate(new Date(this.value)) : ''
  }

  // data
  isDropdownOpen = false
  toLocalShortDate = toLocalShortDate

  public open(): void {
    if (!this.disabled) {
      this.isDropdownOpen = true
    }
  }

  public hide(): void {
    this.isDropdownOpen = false
    this.$emit('blur')
  }
}
